import { getMetaTagValue } from '@fiverr-private/layout-lib/getMetaTagValue';

interface InterceptorEnrichment {
    url: string;
    referrer: string;
    readyState: string;
    facility: string;
    creation: string;
    locale: string;
    pagename: string;
    is_known_crawler: boolean;
    suspected_spoof: boolean;
    pxid?: string;
    age?: number;
}

/**
 * Feature detects es6 browser via spread operator support, in order to set spoof flag.
 * @return {Boolean}
 */
function detectES6() {
    try {
        eval('const o={},x={...o};new Map()'); // eslint-disable-line no-eval
        return true;
    } catch (e) {
        return false;
    }
}

let data: InterceptorEnrichment;

/**
 * Create a clean stack.
 */
export function enrichment(): InterceptorEnrichment {
    data = data || {
        url: window.location.href,
        referrer: document.referrer,
        facility: getMetaTagValue('fiverrmeta:facility') || 'web',
        creation: getMetaTagValue('fiverrmeta:creation'),
        locale: getMetaTagValue('fiverrmeta:locale'),
        pagename: getMetaTagValue('fiverrmeta:pagename'),
        is_known_crawler: getMetaTagValue('fiverrmeta:is_known_crawler') === 'true',
        suspected_spoof: !detectES6() && getMetaTagValue('es6_browser') === 'true'
    };

    data.readyState = document.readyState;
    try {
        data.pxid = window.sessionStorage.getItem('pxsid') || undefined;
        data.age = Math.round(performance.now() / 1000);
    } catch { /* ignores */ }

    return data;
}
