import { AxiosError, AxiosInstance } from 'axios';
import * as interceptor from 'perimeterx-axios-interceptor';
import { logger, stats } from '@fiverr-private/obs';
import { enrichment } from './enrichment';
import { reportInterceptAfterSolve, updateSolveSuccess } from './challengeCookie';
import {
    INTERCEPTOR_EVENTS,
    INTERCEPTOR_MESSAGES,
    LOG_CODE,
    IGNORE_PATTERN
} from './constants';

/**
 * Create a stat metric entry
 * @param key Unique metric key
 */
const count = (key: string) => stats.count('axios.interceptor.perimeterx', key);

/**
 * Attach Axios interceptor
 * @param axios - The axios instance to attach the interceptor to
 */
const register = (axios: AxiosInstance) => {
    if (!axios) {
        return;
    }

    // Attach PerimeterX interceptor
    interceptor.attach(axios, {

        /**
         * Should this route being blocked result in the user view the exonerate GUI
         * @param path
         */
        filter: ({ path }: { path: string }): boolean => !IGNORE_PATTERN.test(
            path.replace(/^https:\/\/\w{2,4}\.fiverr\.com/, '')
        ),

        /**
         * Report blocked request interception
         * Track challenge solvers via cookie
         * @param request Original request
         */
        onintercept: (request: Request) => {
            const type = INTERCEPTOR_EVENTS.INTERCEPT;
            count(type);

            logger.force.info({
                message: INTERCEPTOR_MESSAGES[type],
                code: LOG_CODE,
                type,
                url: request.url
            }, enrichment());
            reportInterceptAfterSolve(request);
        },

        /**
         * Report ignored blocked request interception
         * @param request Original request
         */
        onignore: (request: Request) => {
            const type = INTERCEPTOR_EVENTS.IGNORE;
            count(type);
            logger.force.info({
                message: INTERCEPTOR_MESSAGES[type],
                code: LOG_CODE,
                type,
                url: request.url
            }, enrichment());
        },

        /**
         * Report successful exoneration process
         * Add / Update cookie for tracking challenge solvers
         * @param request Original request
         */
        onsuccess: (request: Request) => {
            const type = INTERCEPTOR_EVENTS.SUCCESS;
            count(type);
            logger.force.info({
                message: INTERCEPTOR_MESSAGES[type],
                code: LOG_CODE,
                type,
                url: request.url
            }, enrichment());
            updateSolveSuccess();
        },

        /**
         * Report unsuccessful exoneration process
         * @param request Original request
         * @param error Error thrown by axios
         */
        onfailure: (request: Request, error: AxiosError) => {
            const type = INTERCEPTOR_EVENTS.FAILURE;
            count(type);
            logger.force.warn(error, Object.assign({
                message: [INTERCEPTOR_MESSAGES[type], error.message].join(': '),
                code: LOG_CODE,
                type,
                url: request.url
            }, enrichment()));
        },

        /**
         * Report failure in the process
         * @param error Error thrown by exoneration process
         */
        onerror: (error: AxiosError) => setTimeout(() => {
            if (error.code === 'UNSUPPORTED_BROWSER') {
                return;
            }

            error.code = 'AXIOS_INTERCEPTOR_ERROR';
            throw error;
        }),

        modalConfig: { timeout: 6000 }
    });
};

export default register;
