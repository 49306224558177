import { META_TAGS_SELECTOR_PROPERTIES } from './constants';

/**
 * Returns meta tag selector by tag value.
 * {String} identifier The meta tag's identifier to search in META_TAGS_SELECTOR_PROPERTIES
 */
const getMetaTagSelector = (identifier: string): string => META_TAGS_SELECTOR_PROPERTIES.map((metaProperty) => (
    `meta[${metaProperty}="${identifier}"]`
)).join();

/**
 * Safely gets a meta tag's content by its identifier.
 * @param identifier The meta tag's identifier to search in META_TAGS_SELECTOR_PROPERTIES
 */
export const getMetaTagValue = (identifier: string): string | undefined => {
    if (typeof document === 'undefined') {
        return undefined;
    }

    const tag = document.querySelector(getMetaTagSelector(identifier));

    if (!tag) {
        return undefined;
    }

    return tag.getAttribute('content') || undefined;
};
