(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fiverr/i18n/singleton"), require("axios"), require("lodash"), require("ua-parser-js"));
	else if(typeof define === 'function' && define.amd)
		define(["@fiverr/i18n/singleton", "axios", "lodash", "ua-parser-js"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@fiverr/i18n/singleton"), require("axios"), require("lodash"), require("ua-parser-js")) : factory(root["i18n"], root["axios"], root["_"], root["uaParser"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE_jgnj__, __WEBPACK_EXTERNAL_MODULE__jHP__, __WEBPACK_EXTERNAL_MODULE_luq0__, __WEBPACK_EXTERNAL_MODULE_glLg__) => {
return 