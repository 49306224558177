import { MANDATORY_SCRIPT_SELECTOR } from './constants';

/**
 * Returns true if any of the mandatory scripts had failed to load.
 */
export const mandatoryScriptFailed = () =>
    !!Array.prototype.filter.call(
        document.querySelectorAll(MANDATORY_SCRIPT_SELECTOR),
        (s) => s.failed
    ).length;
