import { mapValues } from 'lodash';

/**
 * The metrics prefix to use when sending stats metrics.
 */
const metricsPrefix = 'global_modules.socket_handler';

/**
 * The possible event topics to be emitted.
 */
const EVENTS = {
    CONNECT: 'connect',
    FETCH_CONFIGURATION: 'fetch_configuration'
};

/**
 * The metrics reported to stats.
 */
export const METRICS = mapValues({
    CONNECT_SUCCESS: `${EVENTS.CONNECT}.success`,
    CONNECT_FAILURE: `${EVENTS.CONNECT}.failure`,
    CONNECT_SKIPPED: `${EVENTS.CONNECT}.skipped`,
    FETCH_CONFIGURATION_SUCCESS: `${EVENTS.FETCH_CONFIGURATION}.success`,
    FETCH_CONFIGURATION_FAILURE: `${EVENTS.FETCH_CONFIGURATION}.failure`
}, (metric: string): string => `${metricsPrefix}.${metric}`);
