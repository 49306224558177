import i18n from '@fiverr/i18n/singleton';
import { logger } from '@fiverr-private/obs';
import { getMetaTagValue } from '@fiverr-private/layout-lib/getMetaTagValue';
import { MODULE_NAME } from './constants';

interface I18nReportRecord {
    code: string;
    details: {
        module: string,
        key: string,
        scope: string,
        result?: unknown,
    };
}

/**
 * Enriches a given record with general details to report with
 * @param record - The record reported.
 */
const enrich = (record: I18nReportRecord) => {
    const url = window.location.href;

    const facility = getMetaTagValue('fiverrmeta:facility') || 'web';
    const meta = {
        is_known_crawler: Boolean(getMetaTagValue('fiverrmeta:is_known_crawler')),
        creation: getMetaTagValue('fiverrmeta:creation'),
        locale: getMetaTagValue('fiverrmeta:locale'),
        referrer: document.referrer
    };

    return Object.assign(
        record,
        { url, facility, meta }
    );
};

/**
 * The on miss event, fired once a given translation key is fully missing.
 */
i18n.onmiss(
    (key, scope) => {
        logger.warn(new Error(`Missing translation: ${key}`), enrich({
            code: 'MISSING_TRANSLATION_ERROR',
            details: {
                module: MODULE_NAME,
                key,
                scope
            }
        }));
    }
);

/**
 * The empty event, fired once a given translation key value is empty.
 */
i18n.onempty(
    (key, result, scope) => {
        logger.warn(new Error(`Empty translation: ${key}`), enrich({
            code: 'EMPTY_TRANSLATION_WARNING',
            details: {
                module: MODULE_NAME,
                key,
                result,
                scope
            }
        }));
    }
);
